html,
body {
  font-size: 18px;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  text-align: center;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.header {
  font-family: 'Athiti', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.x {
  position: fixed;
  color: rgba(0, 0, 0, 0.5);
  top: 10px;
  right: 15px;
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer {
  height: 50px;
  margin-top: -50px;
}

.flex-stretch {
  align-items: stretch;
}

/* .page {
  min-height: 100vh;
  justify-items: center;
  align-items: center;
} */

a,
a:hover {
  text-decoration: none;
}

/* HIDDEN NUMBER INPUT TYPE ARROW*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes progressing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
