.page-slider-exit > .page {
  z-index: 1;
}
.page-slider-enter > .page {
  z-index: 10;
}
.page-slider-enter.left > .page {
  transform: translate3d(100%, 0, 0);
}
.page-slider-enter.right > .page {
  transform: translate3d(-100%, 0, 0);
}
.page-slider-exit.left > .page {
  transform: translate3d(-100%, 0, 0);
}
.page-slider-exit.right > .page {
  transform: translate3d(100%, 0, 0);
}
.page-slider-enter.page-slider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  transition: all 450ms ease-in-out;
}

/* PAGES */
.page-container {
  width: 100%;
  height: 100%;
  font-family: Oxygen, sans-serif;
}

.inner-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
