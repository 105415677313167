/* athiti-regular - thai_latin */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Athiti'), url('./fonts/athiti-v5-thai_latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/athiti-v5-thai_latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Athiti'), url('./fonts/athiti-v5-thai_latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/athiti-v5-thai_latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* FONT SIZE DEFINITION*/
h1 {
  @apply text-4xl;
}
h2 {
  @apply text-3xl;
}
h3 {
  @apply text-2xl;
}
h4 {
  @apply text-xl;
}
h5 {
  @apply text-lg;
}
h6 {
  @apply text-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Athiti', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
