.btn {
  @apply py-3 px-4 rounded focus:outline-none font-bold;
}

$btn-colors: 'red' 'green' 'blue' 'yellow' 'gray' 'indigo' 'purple' 'pink';
@each $color in $btn-colors {
  .btn-#{$color} {
    @apply btn text-white bg-#{$color}-500 hover:bg-#{$color}-700 focus:bg-#{$color}-700;
  }
}

.btn-white {
  @apply btn text-gray-600 bg-white hover:bg-gray-200 focus:bg-gray-200;
}

.btn-outline {
  @apply py-3 px-4 rounded border bg-transparent;
}

.btn-outline-white {
  @apply border-white text-white hover:bg-white hover:text-gray-600;
}

@each $color in $btn-colors {
  .btn-outline-#{$color} {
    @apply border-#{$color}-400 text-#{$color}-400 hover:bg-#{$color}-400 hover:text-white;
  }
}
